import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import FormModal from '../../model/FormModal'
import styles from './Quiz2.module.css'

interface Quiz2Props {
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<string>>

}

const Quiz2: FC<Quiz2Props> = ({formModal, serAnswerNow, setInputValue}) => {

    const [value, setValue] = useState<string>(formModal.formQuiz.question2)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question2)


    function click(e: string) {
        setValue(e)
        setStylesActive(e)
        serAnswerNow(3)
        setInputValue(e)
    }

    useEffect(() => {
        formModal.formQuiz.question2 = value

    }, [value])

    return (
        <div className={styles.quizWrapper}>
            <div className={styles.quizTitle}>Сколько камер необходимо?</div>
            <div className={styles.quizContainer}>

                <div className={styles.quizItem}>
                    <div className={stylesActive == '1-2' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('1-2')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '1-2' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '1-2' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>1-2</div>
                        </div>
                    </div>
                </div>

                <div className={styles.quizItem}>
                    <div className={stylesActive == '3' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('3')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '3' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '3' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>3</div>
                        </div>
                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div className={stylesActive == '4-6' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('4-6')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '4-6' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '4-6' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>4-6</div>
                        </div>
                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div className={stylesActive == '6-8' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('6-8')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '6-8' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '6-8' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>6-8</div>
                        </div>
                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div className={stylesActive == '8-16' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('8-16')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '8-16' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '8-16' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>8-16</div>
                        </div>
                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div className={stylesActive == '16+' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('16+')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == '16+' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == '16+' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>16+</div>
                        </div>
                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div
                        className={stylesActive == 'Не знаю' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                        onClick={() => click('Не знаю')}>
                        <div className={styles.quizItem__title}>
                            <div
                                className={stylesActive == 'Не знаю' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div
                                    className={stylesActive == 'Не знаю' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div>
                            <div>Не знаю. Нужна консультация</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Quiz2