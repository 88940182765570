import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import FormModal from '../../model/FormModal'
import styles from './Quiz2.module.css'

interface Quiz4Props{
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<string>>
}

const Quiz4: FC<Quiz4Props> = ({formModal, serAnswerNow, setInputValue})=>{

    const [value, setValue] = useState<string>(formModal.formQuiz.question4)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question4)



    function click(e:string){
        setValue(e)
        setStylesActive(e)
        serAnswerNow(5)
        setInputValue(e)
    }   

    useEffect(() => {
        formModal.formQuiz.question4 = value
        
    },[value])

    return(
        <div className={styles.quizWrapper}>
        <div className={styles.quizTitle}>Какие есть специфические задачи?</div>
            <div className={styles.quizContainer}>
            
            <div className={styles.quizItem}>
                <div id='1запись звука'  className={stylesActive== 'запись звука' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('запись звука')}>
                    <div className={styles.quizItem__title}>
                        <div id='запись звука' className={stylesActive== 'запись звука' ? styles.quizItem__point_active : styles.quizItem__point}>
                        <div className={stylesActive== 'запись звука' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div><div>Запись звука</div>
                    </div>
                </div>
            </div>
            <div className={styles.quizItem}>
            <div id='Доступ к камерам с телефона'  className={stylesActive== 'Доступ к камерам с телефона' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Доступ к камерам с телефона')}>
                <div className={styles.quizItem__title}>
                    <div id='Доступ к камерам с телефона' className={stylesActive== 'Доступ к камерам с телефона' ? styles.quizItem__point_active : styles.quizItem__point}>
                            <div className={stylesActive== 'Доступ к камерам с телефона' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>Доступ к камерам с телефона</div>
                </div>
                </div>
            </div>
            <div className={styles.quizItem}>
                <div id='Четкая детализация' className={stylesActive== 'Четкая детализация' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Четкая детализация')}>
                <div className={styles.quizItem__title}>
                    <div id='Четкая детализация' className={stylesActive== 'Четкая детализация' ? styles.quizItem__point_active : styles.quizItem__point}>
                    <div className={stylesActive== 'Четкая детализация' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>Четкая детализация</div>
                </div>
                </div>
            </div>
            <div className={styles.quizItem}>
                <div id='Не определился' className={stylesActive== 'Не определился' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Не определился')}>
                    <div className={styles.quizItem__title}>
                        <div id='Не определился' className={stylesActive== 'Не определился' ? styles.quizItem__point_active : styles.quizItem__point}>
                        <div className={stylesActive== 'Не определился' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div><div>Не определился. Нужна консультация</div> 
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default Quiz4