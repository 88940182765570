import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import styles from './Quiz1.module.css'
import image1 from '../../static/image/img1.png'
import image2 from '../../static/image/img2.png'
import image3 from '../../static/image/img3.png'
import image4 from '../../static/image/img4.png'
import image5 from '../../static/image/img5.png'
import FormModal from '../../model/FormModal'

interface Quiz1Props {
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<string>>
}

const Quiz1: FC<Quiz1Props> = ({formModal, serAnswerNow, setInputValue}) => {

    const [value, setValue] = useState<string>(formModal.formQuiz.question1)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question1)


    function click(e: string) {
        setValue(e)
        setStylesActive(e)
        serAnswerNow(2)
        setInputValue(e)
    }

    useEffect(() => {
        formModal.formQuiz.question1 = value

    }, [value])


    return (
        <div className={styles.quizWrapper}>
            <div className={styles.quizTitle}>Куда хотите установить видеонаблюдение?</div>
            <div className={styles.quizContainer}>

                <div className={styles.quizItem}>
                    <div id='Частный дом / Дача'
                         className={stylesActive == 'Частный дом / Дача' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('Частный дом / Дача')}>
                        <img className={styles.quizItem__img} src={image1} alt='image quiz'/>
                        <div className={stylesActive == 'Частный дом / Дача' ? styles.quizItem__point : styles.quizItem__point_mobile}></div>
                        <div className={styles.quizItem__title}>
                            Частный дом / Дача
                        </div>



                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div id='ТС / Кооператив'
                         className={stylesActive == 'ТС / Кооператив' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('ТС / Кооператив')}>
                        <img className={styles.quizItem__img} src={image2} alt='image quiz'/>
                        <div className={stylesActive == 'ТС / Кооператив' ? styles.quizItem__point : styles.quizItem__point_mobile}></div>
                        <div className={styles.quizItem__title}>
                            ТС / Кооператив
                        </div>

                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div id='Гос. объект'
                         className={stylesActive == 'Гос. объект' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('Гос. объект')}>
                        <img className={styles.quizItem__img} width='100%' src={image3} alt='image quiz'/>
                        <div className={stylesActive == 'Гос. объект' ? styles.quizItem__point : styles.quizItem__point_mobile}></div>
                        <div className={styles.quizItem__title}>
                            Гос. объект
                        </div>

                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div id='Частный бизнес'
                         className={stylesActive == 'Частный бизнес' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('Частный бизнес')}>
                        <img className={styles.quizItem__img} src={image4} alt='image quiz'/>
                        <div className={stylesActive == 'Частный бизнес' ? styles.quizItem__point : styles.quizItem__point_mobile}></div>
                        <div className={styles.quizItem__title}>
                            Частный бизнес
                        </div>

                    </div>
                </div>
                <div className={styles.quizItem}>
                    <div id='Другой объект'
                         className={stylesActive == 'Другой объект' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                         onClick={() => click('Другой объект')}>
                        <img className={styles.quizItem__img} src={image5} alt='image quiz'/>
                        <div className={stylesActive == 'Другой объект' ? styles.quizItem__point : styles.quizItem__point_mobile}></div>
                        <div className={styles.quizItem__title}>
                            Другой объект
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Quiz1