import exp from "constants";
import { IForm } from "./IForm";

class FormModal {
    formQuiz: IForm
    phone: string

    constructor(question1: string, question2: string, question3: string, question4: string, question5: string, phone: string){
        this.formQuiz = {
            question1,
            question2,
            question3,
            question4,
            question5,
        }
        this.phone = phone
    }

    

}

export default FormModal