import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react'
import styles from './InputPhone.module.css'
import validationPhone from '../../validation/ValidationPhone'

interface InputPhoneProps{
    setValuePhone: Dispatch<SetStateAction<string>>
    setValidPhone: Dispatch<SetStateAction<boolean>>
    checkValidPhone: boolean
    setCheckValidPhone: Dispatch<SetStateAction<boolean>>
}

const InputPhone: FC<InputPhoneProps> = ({setValuePhone, setValidPhone, checkValidPhone, setCheckValidPhone})=>{
    const [value, setValue] = useState('')

    function phoneMask(e:ChangeEvent<HTMLInputElement>){
        const getInputNumbers = function (input:any) {
            return input.replace(/\D/g, '');
        }
        const phoneInputs = e.target.value
        const nowPhone = []
        const newPhone = []
        for(let i = 0 ; i < phoneInputs.length; i++){
            
            const numbItem = getInputNumbers(phoneInputs[i])
            if(numbItem !== ''){
            nowPhone.push(numbItem)
            } 
        }
        
        for(let i =0 ; i < nowPhone.length; i++){
            const numbItem = getInputNumbers(nowPhone[i])
            if(numbItem !== '' && i < 11 && (nowPhone[0] == 8)){
                if(i === 0 && (nowPhone[0] == 2 || nowPhone[0] == 3 || nowPhone[0] == 4) ){
                    newPhone.push('(')
                }
                if(i === 1 && nowPhone[0] == 8){
                    newPhone.push(' (')
                }
                if(i === 4 && nowPhone[0] == 8){
                    newPhone.push(') ')
                }
                if(i === 7){
                    newPhone.push('-')
                }
                if(i === 9){
                    newPhone.push('-')
                }
                newPhone.push(numbItem)
            } else if(numbItem !== '' && i < 9 && (nowPhone[0] == 2 ||  nowPhone[0] == 3 || nowPhone[0] == 4) && nowPhone.length < 14){
                if(i === 0){
                    newPhone.push('(')
                }
                
                if(i === 2){
                    newPhone.push(') ')
                }
                if(i === 5){
                    newPhone.push('-')
                }
                if(i === 7){
                    newPhone.push('-')
                }
                newPhone.push(numbItem)
            }else{
                return false
            }
            
        }

        setCheckValidPhone(true)
        const phoneNumbers = newPhone.map(item => getInputNumbers(item)) 
        const validPhone = validationPhone(phoneNumbers)
       
        setValidPhone(validPhone)
        setValue(newPhone.join(''))
        
        return phoneNumbers.join('')

    }
    function StyleInputValidPhone(){

        return false
    }

    function changeValue(e:ChangeEvent<HTMLInputElement>){
        const newPhone: string | boolean = phoneMask(e)
        if(newPhone){
            setValuePhone(newPhone)
        }
        
    }

    return(
        <div className={checkValidPhone ? styles.inputWrapper : styles.inputWrapper + ' ' + styles._error}>
            <label className={styles.labelPhone} htmlFor='phone-input'>Введите телефон *</label>
            <input className={styles.inputPhone} placeholder='Введите телефон *' id='phone-input' 
            type={'text'} value={value} onChange={(e) => changeValue(e)} />
                       
        </div>
    )
}
export default InputPhone