import { AxiosResponse } from "axios";
import api from "../http/index";
import { IForm } from "../model/IForm";

export default class QuizService{
    static async sendForm(form: IForm, phone: string): Promise<AxiosResponse<IForm, string>>{
        let labels:{[index:string]: string | null} = {};
        let search = window.location.search.replace('?','');
        
        let params = new URLSearchParams(search);
        for (const key of params.keys()) {
            labels[key] = params.get(key);
        }
        
        const response = await api.post<IForm>('/quiz', {form, phone, labels})
        return response
    }
}