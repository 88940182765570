import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import FormModal from '../../model/FormModal'
import styles from './Quiz2.module.css'

interface Quiz3Props{
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<string>>
}

const Quiz3: FC<Quiz3Props> = ({formModal, serAnswerNow, setInputValue})=>{

    const [value, setValue] = useState<string>(formModal.formQuiz.question3)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question3)



    function click(e:string){
        setValue(e)
        setStylesActive(e)
        serAnswerNow(4)
        setInputValue(e)
    }   

    useEffect(() => {
        formModal.formQuiz.question3 = value
        
    },[value])

    return(
        <div className={styles.quizWrapper}>
        <div className={styles.quizTitle}>Сколько дней требуется хранить запись с камер?</div>
            <div className={styles.quizContainer}>
            
            <div className={styles.quizItem}>
                <div id='до 7 дней'  className={stylesActive== 'до 7 дней' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('до 7 дней')}>
                    <div className={styles.quizItem__title}>
                        <div id='до 7 дней' className={stylesActive== 'до 7 дней' ? styles.quizItem__point_active : styles.quizItem__point}>
                        <div className={stylesActive== 'до 7 дней' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div><div>до 7 дней</div>
                    </div>
                </div>
            </div>
            <div className={styles.quizItem}>
            <div id='до 14 дней'  className={stylesActive== 'до 14 дней' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('до 14 дней')}>
                <div className={styles.quizItem__title}>
                    <div id='до 14 дней' className={stylesActive== 'до 14 дней' ? styles.quizItem__point_active : styles.quizItem__point}>
                            <div className={stylesActive== 'до 14 дней' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>до 14 дней (рекомендуем)</div>
                </div>
                </div>
            </div>
            <div className={styles.quizItem}>
                <div id='до 30 дней' className={stylesActive== 'до 30 дней' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('до 30 дней')}>
                <div className={styles.quizItem__title}>
                    <div id='до 30 дней' className={stylesActive== 'до 30 дней' ? styles.quizItem__point_active : styles.quizItem__point}>
                    <div className={stylesActive== 'до 30 дней' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>до 30 дней</div>
                </div>
                </div>
            </div>
            <div className={styles.quizItem}>
            <div id='Не знаю' className={stylesActive== 'Не знаю' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Не знаю')}>
                <div className={styles.quizItem__title}>
                    <div id='Не знаю' className={stylesActive== 'Не знаю' ? styles.quizItem__point_active : styles.quizItem__point}>
                    <div className={stylesActive== 'Не знаю' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>Не знаю. Нужна консультация</div>
                </div>
                </div>
            </div>
            
            </div>
        </div>
    )
}
export default Quiz3