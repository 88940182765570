import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react'
import ym, { YMInitializer } from 'react-yandex-metrika';
import FormModal from '../../model/FormModal'
import InputPhone from '../Input/InputPhone'
import Quiz1 from '../Quiz/Quiz1'
import Quiz2 from '../Quiz/Quiz2'
import Quiz3 from '../Quiz/Quiz3'
import Quiz4 from '../Quiz/Quiz4'
import Quiz5 from '../Quiz/Quiz5'
import styles from './Form.module.css'
import QuizService from '../../service/QuizService'
import TiktokPixel from 'tiktok-pixel';
import ReactGA from 'react-ga';

import viber from '../../static/image/viber.svg'
import telegram from '../../static/image/telegram.svg'


import useAnalyticsEventTracker from './Google';

interface WindowTmr extends Window{
    _tmr: any[]; // типизируем _tmr как массив любых элементов
}

declare var window: WindowTmr;
interface FormPorps{
    setVisible: Dispatch<SetStateAction<boolean>>
}



const From: FC<FormPorps> = ({setVisible})=>{

    const [phonePage, setPhonePage] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [phoneValue, setPhoneValue] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [checkValidPhone, setCheckValidPhone] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const [page, setPage] = useState<number>(1)
    const [answersAmount, setAnswersAmount] = useState<number>(1)
    const [answerNow, serAnswerNow] = useState<number>(1)
    const [check, setCheck] = useState<boolean>(false)
    const [validCheck, setValidCheck] = useState(true)
    const [useBtn, setUseBtn] = useState<boolean>(true)
    const [formModal, setFormModal] = useState<FormModal>(new FormModal('', '', '', '', '', ''))
    const [formElement, setFormElement] = useState<ReactElement | ReactElement[]>(<Quiz1 setInputValue={setInputValue} serAnswerNow={serAnswerNow} 
        formModal={formModal}/>)

    const YM = getParams('YM') ? [Number(getParams('YM'))] : [93712831];

    useEffect(() => {
        let cookie = ['_fbp'];
        cookie.forEach(item => {
            if (getParams(item)){
                setCookie(item, getParams(item));
            }
        })
    })

    function setCookie(cname: string, cvalue: string | null) {
        const d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('1297375657722698')
                ReactPixel.pageView()
            })
        let tiktok_pixel = getParams('tiktok_pixel') ?? 'CCFJO0BC77U85D4JCGF0';
        TiktokPixel.init(tiktok_pixel);
        TiktokPixel.pageView();

        ReactGA.initialize(getParams('GA') ?? 'UA-183667182-1');

    },[])




    function swapPage(){
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const gaEventTracker = useAnalyticsEventTracker('form');
            switch(page){
                case 1:
                    setFormElement(<Quiz1 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                break
                case 2:
                    setFormElement(<Quiz2 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_1');
                    gaEventTracker('question_1');
                break
                case 3:
                    setFormElement(<Quiz3 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_2');
                    gaEventTracker('question_2');
                break
                case 4:
                    setFormElement(<Quiz4 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_3');
                    gaEventTracker('question_3');
                break
                case 5:
                    setFormElement(<Quiz5 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_4');
                    gaEventTracker('question_4');
                break
                case 6:
                    clickNextPhone();
                    ym('reachGoal', 'question_5');
                    gaEventTracker('kvizform');

                break
               
                default: setFormElement(<div> error</div>)
                break
               
            }
            setUseBtn(true)
      
        
    }

    async function fetchDataForm(){
        setDisableButton(true)
        if(check && validPhone){
            if(phoneValue.split('')[0] !== '8'){
                formModal.phone = '+375' + phoneValue
            } else {
                formModal.phone = phoneValue
            }
            const response = await QuizService.sendForm(formModal.formQuiz, formModal.phone)
            if(response){
                setDisableButton(false)
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        ReactPixel.init('1297375657722698')
                        ReactPixel.track('Lead', 'quiz_finish');
                    })

                ym('reachGoal', 'zayvka')
                TiktokPixel.track('CompleteRegistration');
                setSuccess(true);


                ReactGA.ga('event', 'kvizsuccess');

                window._tmr.push({ type: 'reachGoal', id: 3498247, goal: 'lead'});

                // eslint-disable-next-line react-hooks/rules-of-hooks
                const gaEventTracker = useAnalyticsEventTracker('form');
                gaEventTracker('kvizsuccess');
            }
        } 
        if(!check){
            setValidCheck(false)
            setDisableButton(false)
        } 
        if(!validPhone){
            setCheckValidPhone(false)
            setDisableButton(false)
        }
        
    }

    function newForm(){
        setFormModal(new FormModal('', '', '', '', '', ''))
        setPhonePage(false)
        setPhoneValue('')
        setValidPhone(false)
        setPage(1)
        setCheck(false)
        setCheckValidPhone(true)
        setUseBtn(true)
        serAnswerNow(1)
        setAnswersAmount(1)
    }

    function clickNextPhone(){
        if(formModal.formQuiz.question1 && formModal.formQuiz.question2 &&
            formModal.formQuiz.question3 && formModal.formQuiz.question4 && formModal.formQuiz.question5){
            setPhonePage(true)
        } else {
            setPage(page-1)
        }
        
    }

    function clickNext(){
        if(page < 5 && page < answersAmount && useBtn){
            setPage(page +1)
        }
        
       
    }

    function clickPrev(){
        if(page > 1 && useBtn){
            setPage(page-1)
        }
    }

    function getParams(key: string){
        let search = window.location.search.replace('?','');

        let params = new URLSearchParams(search);
        return params.get(key)
    }

    function changeAnswer(){
        if(answerNow > answersAmount){
                setAnswersAmount(answerNow)
        }
        setUseBtn(false)
        setInputValue('');
        setTimeout(() => {
            setPage(answerNow)
            setUseBtn(true)

        }, 800)
        
    }

    function validationCheck(e: boolean){
        setCheck(e)
        if(check){
            setValidCheck(true)
        }
    }
    
    useEffect(() => {
        swapPage()
    },[page])


    useEffect(() => {
        changeAnswer()
    },[inputValue])


    return(

        <div className={styles.wrapperForm}>
            <YMInitializer accounts={YM} options={{defer: true, clickmap: true, trackLinks: true ,accurateTrackBounce: true, webvisor: false}} />
        {!phonePage ?
            <div className={styles.containerForm}>
            <div className={styles.bodyForm}>
               {formElement}
            </div>

            <div className={styles.footerForm}>
                <div className={styles.footerForm__info}>
                    <div>Шаг {page} из 5</div>
                    <div className={styles.footerForm__info__steps}>
                        <div className={page >= 1 ? styles.footerForm__info__step_circle_active : styles.footerForm__info__step_circle}></div>
                        <div className={page >= 2 ? styles.footerForm__info__step_circle_active : styles.footerForm__info__step_circle}></div>
                        <div className={page >= 3 ? styles.footerForm__info__step_circle_active : styles.footerForm__info__step_circle}></div>
                        <div className={page >= 4 ? styles.footerForm__info__step_circle_active : styles.footerForm__info__step_circle}></div>
                        <div className={page >= 5 ? styles.footerForm__info__step_circle_active : styles.footerForm__info__step_circle}></div>

                    </div>
                </div>
                <div className={styles.footerForm__btnContainer}>
                    <button className={styles.footerForm__btnContainer__prevBtn} onClick={() => clickPrev()}>
                    <svg viewBox="0 0 24 24" className={styles.footerForm__btnContainer__prevBtn__svg}><title>mdi-arrow-left</title><path className={styles.path} d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" strokeWidth="0" fillRule="nonzero"></path></svg>
                        </button>
                    {page !== 5
                    ?<button className={page < answersAmount ? styles.footerForm__btnContainer__nextBtn : styles.footerForm__btnContainer__nextBtn_cancel} onClick={() => clickNext()}>
                        Далее  <svg viewBox="0 0 24 24" className={styles.footerForm__btnContainer__nextBtn__svg}><title>mdi-arrow-right</title><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" strokeWidth="0" fillRule="nonzero"></path></svg>
                        </button>
                    :  <button className={page < answersAmount ? styles.footerForm__btnContainer__nextBtn : styles.footerForm__btnContainer__nextBtn_cancel}>
                        Последний шаг
                        </button>
                    }
                </div>      
            </div>
        </div>
    : <div className={styles.phoneContainer}>
                {
                    !success ?
                        <>
                            <div className={styles.phoneTitle}>
                                <span className={styles.phoneTitle__text}>Отлично, теперь мы знаем, что вам нужно!</span>
                                <div className={styles.phoneTitle__progresBar}>
                                    <div className={styles.finalPage__progress}><div className={styles.progressBarLinear}>
                                        <div className={styles.progressBarLinear__field}><span className={styles.progressBarLinear__field__span}></span>
                                            <div className={styles.progressBarLinear__fieldMark} >
                                                <div className={styles.progressBarLinear__fieldPercent}>95%</div>
                                                <div className={styles.progressBarLinear__fieldPointer}></div></div></div></div></div>
                                </div>
                                <h2>Куда вам отправить смету?</h2>
                                {
                                    <ul className={styles.consent}>

                                        <li>
                                            <a href="https://drive.google.com/file/d/1sAfxYlahTaMObg5vcpI4RFn-ZJBh2AH-/view" target="_blank">
                                                Информация пользователю сайта до получения согласия
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://drive.google.com/file/d/1NYKuR5OzanMEPlTYOaRa_URIhbfSQqey/view" target="_blank">
                                                Согласие пользователя сайта на обработку перс. данных
                                            </a>
                                        </li>
                                </ul>
                                }
                                <span>Введите свой телефон что бы получить коммерческое предложение</span>
                            </div>
                            <div className={styles.phoneWindow}>
                                <div className={styles.phoneWindow__title}><h2>ВВЕДИТЕ ТЕЛЕФОН</h2></div>

                                <div className={styles.inputContainer}>

                                    <div className={styles.countryBlock}>
                                        <label className={styles.countryBlock__label} htmlFor="">страна</label>
                                        <div className={styles.countryBlock__text}>
                                            <div className={styles.countryBlock__text__flag}></div>
                                            +375
                                        </div>
                                    </div>
                                    <InputPhone
                                        setValidPhone={setValidPhone}
                                        setValuePhone={setPhoneValue}
                                        setCheckValidPhone={setCheckValidPhone}
                                        checkValidPhone={checkValidPhone}
                                    />
                                </div>


                                <div className={styles.accept_text}>
                                    Ознакомившись с размещенными на сайте Политикой в отношении обработки персональных данных, с Информацией, предоставляемой до получения согласия, и текстом Согласия на обработку персональных данных, Я ДАЮ СОГЛАСИЕ НА ОБРАБОТКУ МОИХ ПЕРСОНАЛЬНЫХ ДАННЫХ в соответствии с указанными в них целями и перечнем персональных данных
                                </div>
                                <div className={styles.phoneWindow__checkbox}>
                                    <input onChange={(e) => validationCheck(e.target.checked)} checked={check} className={validCheck ? styles.phoneWindow__checkbox__input : styles.phoneWindow__checkbox__input + ' ' + styles._error} type="checkbox"  id='phoneWindowCheckbox'/>
                                    <label htmlFor="phoneWindowCheckbox">
                                        <span className={styles.phoneWindow__checkbox__text}>Я согласен с обработкой персональных данных</span>
                                    </label>
                                </div>

                                <button className={styles.phoneWindow__btn} onClick={() => fetchDataForm()} disabled={disableButton}><span>
        <svg viewBox="0 0 24 24" className={styles.phoneWindow__btn__icon}><title>mdi-checkbox-marked-circle-outline</title><path d="M20,12C20,16.42 16.42,20 12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" strokeWidth="0" fillRule="nonzero"></path></svg>
            </span>
                                    <span className={styles.phoneWindow__btn__text}>Получить смету</span>
                                </button>

                            </div>
                        </>  :
                        <>
                            <div className={styles.success_header}>Спасибо, что оставили заявку!</div>
                            <div className={styles.success_text}>Наш специалист свяжется с вами в ближайшее время</div>
                        </>
                }

    </div>
    }
    </div>
    )
}

/*
<div className={styles.success_text}></div>
<div className={styles.success_text}>Полезные материалы:</div>
<div className={styles.success_text}>«3 главные ошибки в выборе видеонаблюдения»</div>
<div className={styles.success_text}>«10 обманов продавцов камер»</div>
<div className={styles.success_text}>Вы получите мгновенно в мессенджер &#128071;</div>
<div className={styles.btn}>
    <a className={styles.tg} href="tg://resolve?domain=videocamery_bot&start=ml2" target='_blank'><img src={telegram} /> Telegram</a>
    <a className={styles.viber} href="viber://pa?chatURI=7video2013&context=c1664194914956" target='_blank'><img src={viber} /> Viber</a>
</div>
 */
export default From