import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import FormModal from '../../model/FormModal'
import styles from './Quiz2.module.css'

interface Quiz5Props{
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<string>>
}

const Quiz5: FC<Quiz5Props> = ({formModal, serAnswerNow, setInputValue})=>{

    const [value, setValue] = useState<string>(formModal.formQuiz.question5)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question5)
    const active = 'active'


    function click(e:string){
        setValue(e)
        setStylesActive(e)
        serAnswerNow(6)
        setInputValue(e)
    }   

    useEffect(() => {
        formModal.formQuiz.question5 = value
    },[value])

    return(
        <div className={styles.quizWrapper}>
        <div className={styles.quizTitle}>Что Вас интересует?</div>
            <div className={styles.quizContainer}>
            

            <div className={styles.quizItem}>
            <div id='Только оборудование'  className={stylesActive== 'Только оборудование' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Только оборудование')}>
                <div className={styles.quizItem__title}>
                    <div id='Только оборудование' className={stylesActive== 'Только оборудование' ? styles.quizItem__point_active : styles.quizItem__point}>
                            <div className={stylesActive== 'Только оборудование' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                        </div><div>Только оборудование</div>
                </div>
                </div>
            </div>
                <div className={styles.quizItem}>
                    <div id='Оборудование и монтаж'  className={stylesActive== 'Оборудование и монтаж' ? styles.quizItem__wrapper_active : styles.quizItem__wrapper} onClick={() => click('Оборудование и монтаж')}>
                        <div className={styles.quizItem__title}>
                            <div id='Оборудование и монтаж' className={stylesActive== 'Оборудование и монтаж' ? styles.quizItem__point_active : styles.quizItem__point}>
                                <div className={stylesActive== 'Оборудование и монтаж' ? styles.quizItem__pointIn_active : styles.quizItem__pointIn}></div>
                            </div><div>Оборудование и монтаж</div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}
export default Quiz5